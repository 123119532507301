<template>
    <div class="comment_info" v-if="info">
        <div class="hd">
            <div class="userpic">
                <VanImage width="100%" height="100%" fit="cover" lazy-load :src="info.userinfo.avatar" />
            </div>
            <div class="rgcon">
                <div class="rghd acea-row row-between-wrapper">
                    <div>
                        <div class="name Ellipses1">{{info.userinfo.nickname}}</div>
                        <div class="grade">
                            <span class="bg-color">{{info.userinfo.rank_name}}</span>
                            <div class="zhi">
                                <div>
                                    <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                </div>
                            </div>
                            <span class="font-color">酒量值：{{info.userinfo.pr}}</span>
                        </div>
                    </div>
                    <div :class="'follow follow'+info.userinfo.is_follow" @click="is_follow(info.userinfo,info.userinfo.is_follow)" >{{info.userinfo.is_follow==2?'互相关注':(info.userinfo.is_follow?'取消关注':'关注')}}</div>
                </div>
            </div>
            <!--
            <div class="add font-color border-color">
                <Icon name="plus">关注</Icon>
            </div>
            -->
        </div>
        <Swipe @change="onChange">
            <SwipeItem v-for="(item, index) in info.images" :key="index">
                <div class="slidepic">
                    <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item" @click.stop="bindImagePreview(index)"/>
                </div>
            </SwipeItem>
            <template #indicator>
                <div class="custom-indicator">
                {{ current + 1 }}/{{info.images.length}}
                </div>
            </template>
        </Swipe>
        <div class="comment_content">
            {{info.content}}
        </div>
        <div class="comment_goods" v-if="info.product_info.store_name">
            <div class="goods">
                <div class="dpic">
                    <VanImage width="100%" height="100%" lazy-load fit="cover" :src="info.product_info.wcj_images" />
                </div>
                <div class="hd">
                    <div class="tit">{{info.product_info.store_name}}</div>
                    <div class="htit">
                        <div class="desc Ellipses1">{{info.product_info.country}} | {{info.product_info.countries}} | {{info.product_info.chateau_name}} | {{info.product_info.grape}}</div>
                    </div>
                    <div class="htit">
                        <div class="Rate">
                            <span class="Rate_num">{{info.product_info.judge_score}}</span>
                            <Rate v-model="info.product_info.judge_score/2" readonly allow-half :size="25" color="#ec652b" void-icon="star" void-color="#eee"/>
                        </div>
                        <div class="tiao Ellipses1">{{info.product_info.judge_count}}条</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tag">
            <span v-for="(item, index) in info.occasion" :key="index">{{item.brand_name}}</span>
        </div>
        <div class="pageviews">
            浏览 {{info.visit}}
        </div>
        <div class="zanlist">
            <div class="zan" :class="info.is_zan==1?'font-color':''" @click="bindJudgeZan()">
                <Icon name="good-job-o" />
                <p>{{info.zan_num}}</p>
            </div>
            <div class="zanuser">
                <div class="userlist" v-if="info.zan_avatar.length">
                    <div class="pic" v-for="(item, index) in info.zan_avatar" :key="index">
                        <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.avatar" />
                    </div>
                    <!--
                    <div class="pic" @click="goPages('/Tasting/wine/zan?id=1')">
                        更多
                    </div>
                    -->
                </div>
                <div class="usernone" v-else>
                    快来点第一个赞吧~
                </div>
            </div>
        </div>
        <div class="comment_list">
            <div class="tit">评论<span>（{{info.comment_num}}）</span></div>
            <div class="hdd">
                <div class="pic">
                    <VanImage width="100%" height="100%" fit="cover" lazy-load  :src="require('@assets/logo.png')" />
                </div>
                <div class="input" @click="bindshowinput('说点什么吧...', 0)">
                    <Field placeholder="说点什么吧..." readonly />
                </div>
            </div>
            <div class="bd">
                <div v-for="(item, index) in list" :key="index">
                    <div class="item">
                        <div class="pic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load  :src="item.avatar" />
                        </div>
                        <div class="rcon">
                            <div class="rconhd acea-row row-between-wrapper">
                                <div>
                                    <div style="overflow: hidden;">
                                        <span class="username Ellipses1">{{item.nickname}}</span>
                                        <span class="grade bg-color">{{item.rank_name}}</span>
                                    </div>
                                    <div class="time">{{item.how_long}}</div>
                                </div>
                                <div class="rgicon">
                                    <Icon name="good-job-o" :class="item.is_zan==1?'font-color':''" @click="bindCommentZan(item, 0)">{{item.zan_num}}</Icon>
                                    <!-- <Icon name="ellipsis" style="font-size:0.5rem;"  @click="bindmoreindex(index)"/> -->
                                    <!-- <div class="Reply" v-if="moreindex === index" @click.stop="bindshowinput('@'+item.nickname, 1, item)" >回复</div> -->
                                    <div style="background: #fff;
                                                        color: #333;
                                                        -webkit-box-shadow: 0 0 0.2rem rgb(0 0 0 / 10%);
                                                        box-shadow: 0 0 0.2rem rgb(0 0 0 / 10%);
                                                        right: 0;
                                                        text-align: center;
                                                        border-radius: 0.1rem;
                                                        border: 1px solid #eee;
                                                        float: left;
                                                        padding: 0.05rem 0.2rem;
                                                        " @click.stop="bindshowinput('@'+item.nickname, 1, item)" >回复</div>
                                </div>
                            </div>
                            <div class="rconbd" v-html="item.content.replace(/\[[\u4E00-\u9FA5]{1,3}\]/gi, emotion)"></div>
                        </div>
                    </div>
                    <div class="item" style="padding-left:1rem;" v-for="(sonitem, sonindex) in item.reply_list" :key="sonindex">
                        <div class="pic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load  :src="sonitem.from_avatar" />
                        </div>
                        <div class="rcon">
                            <div class="rconhd acea-row row-between-wrapper">
                                <div>
                                    <div style="overflow: hidden;">
                                        <span class="username Ellipses1">{{sonitem.from_nickname}}</span>
                                        <span class="grade bg-color">{{sonitem.rank_name}}</span>
                                    </div>
                                    <div class="time">{{sonitem.how_long}}</div>
                                </div>
                                <div class="rgicon">
                                    <Icon name="good-job-o" :class="sonitem.is_zan==1?'font-color':''" @click="bindCommentZan(sonitem, 1)">{{sonitem.zan_num}}</Icon>
                                </div>
                            </div>
                            <div class="rconbd" v-html="sonitem.content.replace(/\[[\u4E00-\u9FA5]{1,3}\]/gi, emotion)"></div>
                        </div>
                    </div>
                </div>
                
                <div class="Loading" v-if="loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="finished">没有更多了~</div>
            </div>
        </div>
        
        <div class="footernav">
            <div class="input" @click="bindshowinput('说点什么吧...', 0)">
                <Field placeholder="说点什么吧..." readonly />
            </div>
            <div class="Icon">
                <Icon name="good-job-o" :class="info.is_zan==1?'font-color':''" @click="bindJudgeZan()">{{info.zan_num}}</Icon>
                <Icon name="like-o" :class="info.is_collect==1?'font-color':''" @click="bindJudgeCollect()">{{info.collect_num}}</Icon>
                <Icon name="chat-o">{{info.comment_num}}</Icon>
            </div>
        </div>
        <Popup v-model="showinput" position="bottom">
            <div class="container">
                <div class="inputcon">
                    <div class="input">
                        <Form @submit="onSubmit">
                            <Field v-model="inputcontent" type="textarea" autosize maxlength="250" rows="1" :placeholder="placeholder" ref="Field" />
                        </Form>
                    </div>
                    <div class="icon" @click="bindemotion">
                        <i class="iconfont" :class="showemotion?'iconjianpanshuru':'iconbiaoqing'"></i>
                    </div>
                    <div class="icon" @click="onSubmit">
                        <!-- <i class="iconfont iconfasong"></i> -->
                        <div class="fasong" style="font-size: 0.1rem;line-height: 1rem;font-weight: 600;">发送</div>
                    </div>
                </div>
                <!-- <div class="text-place" contenteditable="true" @input="changeTxt">
                    <span v-html="content.replace(/\[[\u4E00-\u9FA5]{1,3}\]/gi, emotion)"></span>
                </div> -->
                <emotion @emotion="handleEmotion" :height="4" v-if="showemotion" ></emotion>
            </div>
        </Popup>
    </div>
</template>

<script>
    import Emotion from '@/components/Emotion/index';
    import { setFollow, JudgeDetails, JudgeZan, JudgeCollect, Commentsave, commentList, CommentZan, Replysave, replyList } from "@api/tasting";
    import { Icon, List, Swipe, SwipeItem, Rate, Field, Popup, Form, Overlay, Toast, ImagePreview, Loading } from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "comment_info",
        data () {
            return {
                id: '',
                info: '',
                current: 0,
                userlist: [1,2,3,4,5],
                showinput: false,
                showemotion: false,

                inputcontent: '',
                placeholder: '说点什么吧...',
                comment: '',
                Commenttype: 0,

                to_uid: '',
                comment_id: '',
                reply_id: '',

                commentlist: [{
                    id: 0
                }],
                moreindex: '',
                listindex: 0,

                list: [],
                loading: false,
                finished: false,
                page: 1,
                limit: 5,
                follow:0,
            }
        },
        components: {
            VanImage, Icon, Swipe, SwipeItem, Rate, Field, Popup, Emotion, Form, Overlay, ImagePreview, List, Loading
        },
        created() {
            this.id=this.$route.query.id;
        },
        mounted: function() {
            this.getJudgeDetails();
            this.Loadmore();
            var that=this;
            window.addEventListener('click',function(e){
                if(e.target.className!='van-icon van-icon-ellipsis'){
                    that.moreindex='';
                }
            })
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            //关注
            is_follow(item,index){
               if(!item.my_uid){
                   Toast.loading({
                    duration: 0,
                    message: '请先登录!',
                    forbidClick: true,
                });
               }
               let mag= '';
               if(!index){
                   mag="关注中";
                   index=1;
               }else{
                   index=0;
                   mag="取消中";
               }
               Toast.loading({
                    duration: 0,
                    message: mag,
                    forbidClick: true,
                });
               setFollow({
                   uid:item.my_uid,
                   to_uid:item.uid
               }).then(res=>{
                    Toast.clear();
                    this.$set(item,'is_follow',index);
               }).catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
              
           },
            //预览图片
            bindImagePreview: function(index) {
                ImagePreview({
                    images: this.info.images,
                    startPosition: index,
                });
            },
            bindmoreindex: function(index) {
                this.moreindex=index;
                this.listindex=index;
            },
            getJudgeDetails: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                JudgeDetails({
                    judge_id: this.id,
                }).then(res => {
                    Toast.clear();
                    this.info=res.data;
                    //this.follow = this.info.userinfo.is_follow;
                    this.$set(this.info,'is_follow',this.info.userinfo.is_follow);
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            Loadmore: function() {
                if(this.loading) return;
                if(this.finished) return;
                this.getcommentList();
            },
            getcommentList: function() {
                this.loading = true;
                commentList({
                    topic_id: this.id,
                    page: this.page,
                    limit: this.limit,
                }).then(res => {
                    var listdata = res.data.data;
                    console.log(listdata.length)
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.loading = false;
                    this.page++;
                    if(listdata.length<5){
                        this.finished = true;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            bindJudgeZan: function() {
                Toast.loading({
                    duration: 0,
                    message: '点赞中...',
                    forbidClick: true,
                });
                JudgeZan({
                    judge_id: this.id,
                }).then(res => {
                    if(this.info.is_zan==0){
                        this.info.is_zan=1;
                        this.info.zan_num++
                    }else{
                        this.info.is_zan=0;
                        this.info.zan_num--
                    }
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            bindCommentZan: function(item, type) {
                Toast.loading({
                    duration: 0,
                    message: '点赞中...',
                    forbidClick: true,
                });
                CommentZan({
                    judge_id: item.id,
                    type: type,
                }).then(res => {
                    if(item.is_zan==0){
                        item.is_zan=1;
                        item.zan_num++
                    }else{
                        item.is_zan=0;
                        item.zan_num--
                    }
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            bindJudgeCollect: function() {
                Toast.loading({
                    duration: 0,
                    message: '收藏中...',
                    forbidClick: true,
                });
                JudgeCollect({
                    judge_id: this.id,
                }).then(res => {
                    if(this.info.is_collect==0){
                        this.info.is_collect=1;
                        this.info.collect_num++
                    }else{
                        this.info.is_collect=0;
                        this.info.collect_num--
                    }
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            onChange(index) {
                this.current = index;
            },
            bindshowinput(e, type, item, replyid) {
                this.placeholder= e;
                this.Commenttype=type;
                if(item){
                    this.to_uid=item.uid;
                    this.comment_id=item.id;
                    this.reply_id=item.id;
                }
                if(replyid){
                    this.reply_id=replyid;
                }
                
                this.content = '',
                this.showinput = true;
                this.showemotion = false;
                this.$nextTick(() => {
                    var that=this;
                    setTimeout(function(){ 
                        that.$refs.Field.focus(); 
                    }, 500);
                })
                
            },
            bindemotion(index) {
                this.showemotion = !this.showemotion;
            },
            onSubmit() {
                if(this.inputcontent==''){
                    Toast.fail({
                        message: '请输入评论内容',
                        forbidClick: true,
                    });
                }else{
                    if(this.Commenttype==0){
                        this.bindCommentsave();
                    }else{
                        this.bindReplysave();
                    }
                }
            },
            bindCommentsave() {
                Toast.loading({
                    duration: 0,
                    message: '提交中...',
                    forbidClick: true,
                });
                Commentsave({
                    topic_id: this.id,
                    topic_type: 1,
                    content: this.inputcontent,
                }).then(res => {
                    this.showinput = false;
                    this.showemotion = false;
                    this.inputcontent = '';
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });

                    this.list=[];
                    this.loading=false;
                    this.finished=false;
                    this.page=1;
                    this.limit=5;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            bindReplysave() {
                Toast.loading({
                    duration: 0,
                    message: '回复中...',
                    forbidClick: true,
                });
                Replysave({
                    to_uid: this.to_uid,
                    comment_id: this.comment_id,
                    reply_id: this.reply_id,
                    content: this.inputcontent,
                }).then(res => {
                    this.showinput = false;
                    this.showemotion = false;
                    this.inputcontent = '';
                    this.getreplyList();
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                    });
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
                
            },
            getreplyList: function() {
                replyList({
                    comment_id: this.list[this.listindex].id,
                    page: 1,
                    limit: 3,
                }).then(res => {
                    this.list[this.listindex].reply_list=res.data.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },

            changeTxt(e){
                this.inputcontent= this.inputcontent + e.data;
                this.keepLastIndex(e.target);
            },
            keepLastIndex(obj) {
                console.log(obj)
                console.log(window.getSelection)
                console.log(document.selection)
                if (window.getSelection) { //ie11 10 9 ff safari
                    obj.focus(); //解决ff不获取焦点无法定位问题
                    var range = window.getSelection(); //创建range
                    range.selectAllChildren(obj); //range 选择obj下所有子内容
                    range.collapseToEnd(); //光标移至最后
                } else if (document.selection) { //ie10 9 8 7 6 5
                    var range = document.selection.createRange(); //创建选择对象
                    //var range = document.body.createTextRange();
                    range.moveToElementText(obj); //range定位到obj
                    range.collapse(false); //光标移至最后
                    range.select();
                }
            },

            handleEmotion (i) {
                this.inputcontent += i
            },
            // 将匹配结果替换表情图片
            emotion (res) {
                let word = res.replace(/\[|\]/gi,'')
                const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
                let index = list.indexOf(word)
                return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`   
            }
        },
    }
</script>

<style scoped lang="scss">
    .follow{
        background-color: #f00;
        padding: 0.1rem 0.2rem;
        border-radius: 0.1rem;
        color: #fff;
        font-weight: 800;
    }
    .follow1,.follow2{
            border: 1px solid #e0e0e0;
            color: #ccc;
            background-color: #fff;
     }
    .container {
        .inputcon{
            display: flex;
            .input{
                flex: 1;padding: 0.16rem 0.1rem 0.16rem;
                .van-cell{background: #f4f4f4;border-radius:1rem;padding: 0.1rem 0.3rem;}
            }
            .icon{
                flex: none;width: 0.8rem;text-align:center;height: 100%;
                i{font-size: 0.5rem;line-height: 1rem;}
            }
        }
        .text {
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 400px;
            resize: none;
            box-sizing: border-box;
            padding: 5px 10px;
            border-radius: 8px;
        }
        .text-place {
            box-sizing: border-box;
            padding: 0.1rem 0.2rem;
            border-radius: 8px;
            background: #f3f3f3;
        }
        .text-place p {
            display: flex;
            align-items: center;
            margin: 0;
        }
    }
  
::v-deep{
    .van-icon-star{font-size: 0.32rem!important;}
    .van-rate__item{padding-right: 0.05rem;}
}
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
    .footernav{
        position: fixed;bottom:0;left:0;right:0;line-height: 1rem;box-shadow: 0 -0.1rem 0.2rem rgba(0, 0, 0, 0.04);display:flex;background:#fff;
        .input{
            flex:none;width: 3rem;
            .van-cell{background: #f4f4f4;border-radius:1rem;margin: 0.16rem 0.1rem 0;height:0.7rem;padding: 0.1rem 0.3rem;}
        }
        .Icon{
            flex:1;display:flex;text-align:center;
            i{flex:1;line-height:1rem;display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center;-webkit-justify-content: center;justify-content: center;}
            i::before{font-size: 0.4rem;}
        }
    }
    .comment_info{
        font-size:0.24rem;padding-bottom:1rem;
        .hd{
            overflow:hidden;position:relative;padding: 0.1rem;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                padding-left: 1.15rem;color: #999;
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
                        span:first-child{padding: 0 0.2rem;}
                        .zhi{
                            float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
                            .van-image{margin-left:-0.12rem;}
                        }
                    }
                    .zhi{text-align: right;}
                }
                .goodsname{font-size: 0.28rem;}
                .pingfen{padding: 0.1rem 0;}
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color:#333;line-height:0.4rem;padding: 0.1rem 0;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                }
                .bpic{
                    overflow:hidden;border-radius:0.4rem;
                    .bpic_dd{
                        width:33.333%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{width:100%!important;}
                    .bpic_dd4{width:50%!important;}
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfd{
                    padding-top: 0.1rem;
                    .rgfdicon{
                        .van-icon{float:left;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                }
            }
            .add{
                position: absolute;top:0.34rem;right:0.1rem;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
                i{display: flex;}
                i::before{font-size:0.28rem;margin-right:0.05rem;}
            }
        }
        .slidepic{
            height: 4rem;
        }
        .custom-indicator {
            position: absolute;right: 0.1rem;bottom: 0.2rem;padding: 0 0.3rem;font-size: 0.28rem;background: rgba(0, 0, 0, 0.1);color:#fff;border-radius:1rem;line-height:0.4rem;
        }
        .comment_content{
            padding: 0.2rem;font-size: 0.28rem;
        }
        .comment_goods{
            margin: 0.2rem;border-top: 1px solid #eee;padding-top: 0.2rem;
            .goods{
                border: 1px solid #eee;border-radius: 0.2rem;padding: 0.1rem;overflow:hidden;box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
                .dpic{
                    width: 0.5rem;float:left;margin: 0 0.4rem;
                }
                .hd{
                    padding: 0.2rem 0 0 0;
                    .tit{font-size: 0.28rem;}
                    .htit{
                        font-size: 0.24rem;color:#999;line-height: 0.4rem;overflow:hidden;
                        .desc{float:left;}
                        .Rate{
                            float:left;display: flex;
                            .Rate_num{color:#ec652b;font-size: 0.36rem;margin-right:0.05rem;}
                            .van-rate{padding-top:0.04rem;}
                        }
                        .tiao{float:left;color:#333;margin-left: 0.05rem;}
                    }
                    .commentnav{
                        font-size: 0.2rem;overflow:hidden;margin: 0.1rem -0.1rem 0;
                        a{
                            display: inline-block;float: left;margin: 0.05rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
                        }
                        
                    }
                }
            }
        }
        .tag{
            padding: 0 0.1rem;font-size:0.24rem;overflow: hidden;
            span{display:block;float:left;background:#f3f3f3;color:#333;padding: 0 0.3rem;margin: 0.1rem;line-height: 0.5rem;border-radius:1rem;}
        }
        .pageviews{
            color:#999;margin: 0.2rem;padding-bottom: 0.2rem;border-bottom: 1px solid #eee;
        }
        .zanlist{
            border-bottom: 1px solid #eee;margin: 0.2rem;padding-bottom: 0.1rem;display: flex;
            .zan{
                text-align: center;width:1rem;padding-top: 0.05rem;
                i{font-size:0.4rem;}
            }
            .userlist{
                .pic{float:left;width:0.7rem;height:0.7rem;display: inline-block;border-radius: 1rem;overflow: hidden;margin:0 0.1rem;text-align: center;line-height: 0.8rem;background:#f3f3f3;color:#333;}
            }
            .zanuser{
                flex: 1;color:#999;
                .userlist{overflow:auto;width:100%;}
                .usernone{line-height: 0.7rem;}
            }
        }
        .comment_list{
            padding: 0.2rem;
            .tit{
                font-size:0.28rem;
                span{font-size:0.24rem;color:#999;}
            }
            .hdd{
                display:flex;padding: 0.2rem 0;
                .pic{width: 0.8rem;height:0.8rem;border-radius:1rem;overflow:hidden;border: 1px solid #dedede;}
                .input{
                    flex:1;
                    .van-cell{background: #f4f4f4;border-radius:1rem;margin-left:0.1rem;height:0.7rem;margin-top:0.05rem;padding: 0.1rem 0.3rem;}
                }
            }
            .bd{
                .item{
                    margin-top:0.2rem;color:#999;overflow:hidden;
                    .pic{float:left;width: 0.7rem;height: 0.7rem;border:1px solid #dedede;border-radius:1rem;overflow:hidden;}
                    .rcon{
                        margin-left:1rem;border-bottom: 1px solid #eee;
                        .rconhd{
                            padding: 0 0 0.1rem;font-size: 0.28rem;
                            .username{font-size:0.24rem;max-width:3rem;color:#333;float:left;line-height: 0.5rem;}
                            .grade{float:left;padding: 0.05rem 0.2rem;border-radius:1rem;margin-left:0.1rem;font-size: 0.24rem;}
                            .time{font-size:0.24rem;}
                            .rgicon{
                                position:relative;
                                i{font-size:0.2rem;color:#333;margin: 0 0.1rem;line-height:0.5rem;float: left;}
                                i:before{font-size:0.5rem;float: left;}
                                .Reply{background:#fff;color:#333;box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);position:absolute;right:0;bottom:-0.7rem;line-height:0.6rem;width: 1.6rem;
                                text-align: center;border-radius: 0.1rem;border:1px solid #eee;z-index: 11;}
                                .Reply::before{
                                    z-index: 11;width: 0;height: 0;border: 0.1rem solid;border-color: transparent transparent #fff;display:block;content:'';position:absolute;right:0.2rem;bottom:0.6rem;
                                }
                                .van-overlay{background-color: rgba(0,0,0,0);}
                            }
                        }
                        .rconbd{padding: 0.1rem 0;color:#333;font-size: 0.28rem;}
                    }
                }
            }
            
        }
    }
</style>